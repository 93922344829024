
import React from "react"
import Layout from "../components/Layout"

export default function Contact() {
  return (
    <Layout>
        <div class="main-left-block">
          <p>&nbsp;</p>
        </div>
        <div class="main-center-block">
          <p>&nbsp;</p>
        </div>
        <div class="main-right-block">
          <p> Get in touch with us  <a href="mailto:info@rhinoathletics.club">info@rhinoathletics.club</a></p>
          <p>We can also be reached at any of our social media accounts below</p>
        </div>

    </Layout>
  )
}